export const TABS = [
  {
    name: "Impact Indicators",
    value: "Impact indicator",
  },
  {
    name: "Outcome Indicators",
    value: "Outcome indicator",
  },
  {
    name: "Modules & Coverage Indicators",
    value: "Coverage / Output indicator",
  },
  // {
  //   name: "Modules and Workplan Tracking Measures",
  //   value: "Process indicator / WPMT",
  // },
];
